<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <section id="main">
      <div
        id="info"
        class="grid_main"
      >
        <VSheet
          color="transparent"
          class="pa-2"
        >
          <div class="mb-4">
            <p class="textTitleMain">
              Student Profile
            </p>
          </div>
          <div class="result_info_container">
            <div class="result_span2">
              <p class="textSubTitle textBold">
                {{ data.data.basic.studentName }}
              </p>
              <p class="textSubTitleNormal">
                Student Name
              </p>
            </div>
            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.admNo }}
              </p>
              <p class="textSubTitleNormal">
                Admission Number
              </p>
            </div>
            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.term }}
              </p>
              <p class="textSubTitleNormal">
                Term
              </p>
            </div>
            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.studentClass }}
              </p>
              <p class="textSubTitleNormal">
                Class
              </p>
            </div>
            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.dateOfBirth }}
              </p>
              <p class="textSubTitleNormal">
                Date Of Birth
              </p>
            </div>

            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.session }}
              </p>
              <p class="textSubTitleNormal">
                Session
              </p>
            </div>
            <div>
              <p class="textSubTitle textBold">
                {{ data.data.basic.classTeacher }}
              </p>
              <p class="textSubTitleNormal">
                Teacher Name
              </p>
            </div>

            <div class="result_span2">
              <p class="textSubTitleNormal textBold">
                {{ data.data.basic.parentName }}
              </p>
              <p class="textSubTitleNormal">
                Parent Name
              </p>
            </div>
          </div>
        </VSheet>
        <div class="mt-3 mb-2">
          <p class="textTitleMain">
            Result Information
          </p>
          <p class="textSubTitleNormal ">
            Please collect the report card from school.
          </p>
        </div>
        <div class="grid_basic">
          <div
            v-for="res in data.data.result"
            :key="res.subject"
          >
            <VCard v-if="res.maximum !== ''">
              <div class="result_container">
                <div class="span_2">
                  <p class="textSubTitle textBold">
                    {{ res.subject }}
                  </p>
                  <p class="textTitle mt-2">
                    {{ res.obtained }}/{{ res.maximum }}
                  </p>
                  <div>
                    <div
                      v-if="res.grade !== ''"
                      class="textSubTitleNormal textBold"
                    >
                      Grade: {{ res.grade }}
                    </div>
                    <div
                      v-else
                      class="mt-4"
                    />
                  </div>
                </div>
                <div class="mt-2">
                  <VProgressCircular
                    :value="res.percent"
                    :color="res.color"
                    :size="64"
                    :width="8"
                    class="ma-0 pa-0"
                  >
                    <span class="textBold">
                      {{ res.percent }}
                    </span>
                  </VProgressCircular>
                </div>
              </div>
            </VCard>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({
    pageTitle: 'result',
    data: '',
    dataArr: '',
  }),

  beforeMount() {
    this.dataArr = this.$store.state.result.data
      .filter(data => data.id === this.$route.params.id);
    if (this.dataArr.length > 0) {
      this.data = this.dataArr[0].data;
    } else {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/text';
@import '../../assets/stylus/result';
</style>
